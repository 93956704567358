<template>
<div>
    <h1 class="heading">請求書</h1>

    <div align=right>
        注文番号：{{ form_value.order_id }}<br>
        請求日：{{ today_jp_format }}<br>
        登録番号：T2120001190842
    </div>

    <div class="atena">
        {{atena}}
    </div>

    <div class="company">
        <div class="bkRGBA">
            〒530-0005<br>
            大阪府大阪市北区中之島３－３－３<br>
            中之島三井ビルディング16F<br>
            株式会社R&amp;C IP WORKS
        </div>
    </div>


    <div class="table">
        <b-table :items="items" :fields="fields"></b-table>
    </div>

    <div class="bikou">
        <p>
            下記口座まで合計金額のお振込をお願いいたします。振込手数料は御社でご負担をお願い致します。
        </p>
        <b-container fluid>
            <b-row>
                <b-col sm="2">金融機関名</b-col>
                <b-col sm="3">：三井住友銀行</b-col>
            </b-row>
            <b-row>
                <b-col sm="2">支店名</b-col>
                <b-col sm="3">：大阪本店営業部</b-col>
            </b-row>
            <b-row>
                <b-col sm="2">科目</b-col>
                <b-col sm="3">：普通</b-col>
            </b-row>
            <b-row>
                <b-col sm="2">口座番号</b-col>
                <b-col sm="3">：8257439</b-col>
            </b-row>
            <b-row>
                <b-col sm="2">口座名義</b-col>
                <b-col sm="3">：株式会社R&amp;C IP WORKS</b-col>
            </b-row>
            <b-row>
                <b-col sm="2">口座名義(ｶﾅ)</b-col>
                <b-col sm="3">：ｶ) ｱｰﾙｱﾝﾄﾞｼｰｱｲﾋﾟｰﾜｰｸｽ</b-col>
            </b-row>
        </b-container>
    </div>
</div>
</template>

<script>
export default  {
    data(){
        return {
            fields:[
                {
                    key:"date", 
                    label: '日付'
                },
                {
                    key: "abst",
                    label: '摘要'
                },
                {
                    key: "amount",
                    label: '数量'
                },
                {
                    key: "price",
                    label: '単価',
                    formatter: (val)=>{
                        if(val == '') {return '';}

                        var arr;
                        arr = String(val).split('.');
                        arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                        return arr.join('.') + " 円";
                        }
                },
                {
                    key: "total_price",
                    label: '金額',
                    formatter: (val)=>{
                        if(val == '') {return '';}

                        var arr;
                        arr = String(val).split('.');
                        arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                        return arr.join('.') + " 円";
                        }
                }
            ]
        }
    },
    props:{
        form_value: {
            type: Object,
            required: true
        },
        atena:{
            type: String,
            required: true
        }
    },
    computed:{
        product_abstract: function(){
            return this.form_value.display_product_name + " " + this.form_value.display_plan_name + "版 "
                     + this.form_value.period + "ヶ月分"  
        },
        today_jp_format:function(){
            var hiduke=this.form_value.order_timestamp.split('-')
            var year = hiduke[0];
            var month = hiduke[1];
            var day = hiduke[2];
            return year+"年"+month+"月"+day+"日";
        },
        tax: function(){
            return this.form_value.total_price_with_tax - this.form_value.total_price_without_tax
        },
        items: function(){
            return [
                { date: this.today_jp_format,
                  abst: this.product_abstract,
                  amount: this.form_value.quantity,
                  price: this.form_value.price,
                  total_price: this.form_value.quantity * this.form_value.price
                },
                { date: '',
                  abst: '　',
                  amount: '',
                  price: '',
                  total_price: ''
                },
                { date: '',
                  abst: '小計',
                  amount: '',
                  price: '',
                  total_price: this.form_value.total_price_without_tax
                },
                { date: '',
                  abst: '消費税',
                  amount: '',
                  price: '',
                  total_price: this.tax
                },
                { date: '',
                  abst: '合計',
                  q: '',
                  price: '',
                  total_price: this.form_value.total_price_with_tax
                },
                { date: '',
                  abst: '　',
                  amount: '',
                  price: '',
                  total_price: ''
                },
                { date: '',
                  abst: '内訳',
                  amount: '',
                  price: '',
                  total_price: ''
                },
                {
                    date: '',
                    abst: '10%対象(税抜き)',
                    amount: '',
                    price: '',
                    total_price: this.form_value.total_price_without_tax
                },
                {
                    date: '',
                    abst: '10%対象消費税',
                    amount: '',
                    price: '',
                    total_price: this.tax
                }
            ]
        }
    },
    filters: {
        to_jp_format_num: function (val) {
            var arr;
            arr = String(val).split('.');
            arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            return arr.join('.');
        }
    }
}
</script>

<style scoped>
.heading{
    margin-top: 50px;
    text-align: center;
    font-size:40px;
    font-weight: 700;
    border-bottom: 1px solid black;
}

.atena {
    margin-left: 70px;
    font-size:24px;
    text-decoration: underline;
}

.total_amount {
    line-height: 60px;
    margin-top: 10px;
    margin-left: 70px;
    padding-left: 30px;
    font-size: 30px;
    border: none;
}

.table {
    margin-left:30px;
    margin-right:50px;
    margin-top: 50px;
    width:800px;
}

.company {
    text-align:right;
    background-image: url("/images/in.png");
    background-repeat: no-repeat;
    background-size: 80px 80px;
    background-position: right bottom;
    padding-right: 70px;
    font-weight: bold;
}

.bikou {
    margin-left:60px;
    margin-top: 100px;
}
</style>

    

