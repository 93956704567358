<template>
<div>
    <h1 class="heading">領収書</h1>

    <div align=right>
        注文番号：{{ form_value.order_id }}<br>
        発行日：{{ today_jp_format }}<br>
        登録番号：T2120001190842
    </div>

    <div class="atena">
        {{atena}}
    </div>

    <div class="amount">
        金額 {{ form_value.total_price_with_tax | to_jp_format_num }} 円
    </div>

    <div align=center>
        但 {{ form_value.display_product_name }} {{ form_value.display_plan_name }}版ライセンス 代として<br>
        上記の金額正に受領いたしました。
    </div>


    <div class="table">
        <b-table :items="items" :fields="fields"></b-table>
    </div>

    <div class="company">
        〒530-0005<br>
        大阪府大阪市北区中之島３－３－３<br>
        中之島三井ビルディング16F<br>
        株式会社R&amp;C IP WORKS
    </div>


</div>
</template>

<script>
export default  {
    data(){
        return {
            fields:[
                    {
                        key: "内訳",
                        label: '内訳'
                    },
                    {
                        key: "amount",
                        label: "　",
                        formatter: (val)=>{
                            var arr;
                            arr = String(val).split('.');
                            arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
                            return arr.join('.') + " 円";
                        }
                    }
                ]
            }
    },
    props:{
        form_value: {
            type: Object,
            required: true
        },
        atena:{
            type: String,
            required: true
        }
    },
    computed:{
        today_jp_format:function(){
            var hiduke=this.form_value.billing_confirmed_timestamp.split('-')
            var year = hiduke[0];
            var month = hiduke[1];
            var day = hiduke[2];
            return year+"年"+month+"月"+day+"日";
        },
        tax: function(){
            return this.form_value.total_price_with_tax - this.form_value.total_price_without_tax
        },
        items: function(){
            return [
                { 内訳: '税率10% 税抜き金額', amount: this.form_value.total_price_without_tax },
                { 内訳: '税率10% 消費税', amount: this.tax }
            ]
        }
    },
    filters: {
        to_jp_format_num: function (val) {
            var arr;
            arr = String(val).split('.');
            arr[0] = arr[0].replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
            return arr.join('.');
        }
    }
}
</script>

<style scoped>
.heading{
    margin-top: 50px;
    text-align: center;
    font-size:40px;
    font-weight: 700;
    border-bottom: 1px solid black;
}

.atena {
    margin-left: 70px;
    font-size:24px;
    text-decoration: underline;
}

.amount {
    text-align: center;
    line-height: 60px;
        margin-top: 10px;
        margin-left: 70px;
        margin-right: 20px;
        padding-left: 30px;
        font-size: 30px;
        border: none;
        background-color: #F6F6F6;
}

.table {
    margin-left:30px;
    margin-top: 50px;
    width: 50%;
}

.company {
    text-align:right;
    background-image: url("/images/in.png");
    background-repeat: no-repeat;
    background-size: 80px 80px;
    background-position: right bottom;
    padding-right: 70px;
    font-weight: bold;
}
</style>

    

