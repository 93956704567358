<template>
  <div>
  
    <app-header>請求書・領収書の発行</app-header>
    
    <form-input></form-input>

  </div>
</template>

<script>
  import FormInput from '@/components/FormInput.vue'
  import AppHeader from '@/components/AppHeader' 

  export default {
    name: 'form_for_receipt',
    components: {
      FormInput,
      AppHeader
    },
    computed: {
    },
    methods:{
    }
}
</script>

<style scoped>
h1, h2 {
  font-weight: normal;
}
a {
  color: #42b983;
}
</style>
