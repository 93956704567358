<template>
<div class="sheets">
    <b-card title="請求書・領収書の発行方法">
        <div>
            <b-alert show variant="light">
            <ol>
                <li>ご注文時の「メールアドレス」と「注文番号」（システムから自動送信されたメールに記載されています）を入力してください</li>
                <li>「注文情報の確認」ボタンをクリックしてください（発行用入力画面が表示されます）</li>
                <li>「宛名」をご記入の上、敬称を選択してください</li>
                <li>「請求書の表示」または「領収書の表示」ボタンをクリックしてください（請求書 または 領収書が表示されます）</li>
                <li>ブラウザの印刷機能(Ctrl + P)を用いてこのページを印刷すると、請求書 または 領収書の表示箇所のみが印刷され、税務上の証憑書類としてお使いいただけます。</li>
            </ol>
            <ul>
                <li>領収書は、弊社での入金確認後に発行が可能となります</li>
                <li>PDF で必要な場合、「Microsoft Print To PDF」などを選択しPDF出力してください</li>
                <li>対応ブラウザ： Edge, Google Chrome, Firefox</li>
                <li>※ 印刷の際には、詳細オプションで「背景のグラフィック」にチェックをいれてください</li>
            </ul>
            </b-alert>
        </div>
    </b-card>   

    <hr>

    <b-card title="注文情報の入力">
      <b-form @submit="onSubmit">
        <b-container fluid>
            <b-row>
                <b-col sm="3"><label>メールアドレス：</label></b-col>
                <b-col sm="9"><b-form-input id="mail" v-model="info.email" type="email" required placeholder="ライセンスのご注文時に入力されたEメールアドレスを入力してください"></b-form-input></b-col>
            </b-row>
            <b-row>
                <b-col sm="3"><label>注文番号：</label></b-col>
                <b-col sm="9"><b-form-input id="key" v-model="info.number" type="number" required placeholder="注文番号を入力してください（注文時に自動送信されたEメールに記載されています）"></b-form-input></b-col>
            </b-row>
        </b-container>
        <div style="margin-top:20px">
            <b-button type="submit" variant="primary">注文情報の確認</b-button>
        </div>
        <div v-if="api_response_failed">
            <b-alert show variant="warning">{{ failed_message }}</b-alert>
        </div>
      </b-form>
    </b-card>   

    <div v-if="api_response.confirm_order">
        <hr>

        <b-card title="発行">
            <b-container fluid>
                <b-row>
                    <b-col sm="2"><label>宛名：</label></b-col>
                    <b-col sm="7">
                        <b-form-input id="mail" v-model="info.name" type="text" required placeholder="請求書・領収書の宛名をご記入ください">
                        </b-form-input>
                    </b-col>
                    <b-col sm="3">
                        <b-form-radio-group id="radios1" v-model="selected" name="radioSubComponent">
                            <b-form-radio value="first" checked="true">様</b-form-radio>
                            <b-form-radio value="second">御中</b-form-radio>
                        </b-form-radio-group>
                    </b-col>
                </b-row>
            </b-container>

            <div style="margin-top:20px">
                <span v-if="api_response.confirm_order" style="margin-right:20px">
                    <b-button @click="create_invoice" variant="primary">請求書の表示</b-button>
                </span>
                <span v-else style="margin-right:20px">
                    <b-button :disabled="!api_response.confirm_order">請求書の表示</b-button>
                </span>

                <span v-if="api_response.confirm_payment">
                    <b-button @click="create_receipt" variant="primary">領収書の表示</b-button>
                </span>
                <span v-else>
                    <b-button :disabled="!api_response.confirm_payment">領収書の表示</b-button>
                </span>
            </div>
        </b-card>
    </div>

    <hr>

    <div v-if="api_response.confirm_order" class="sheet">
        <!-- preview -->
        <div>
            <b-card title="">
                <div v-if="flags.preview_invoice">
                    <div ref="invoice">
                        <form-invoice :form_value="api_response" :atena="atena"/>
                    </div>
                </div>

                <div v-if="flags.preview_receipt">
                    <div ref="receipt">
                        <form-receipt :form_value="api_response" :atena="atena"/>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</div>
</template>

<script>
    import FormInvoice from '@/components/FormInvoice.vue'
    import FormReceipt from '@/components/FormReceipt.vue'

  var axios = require('axios')
  export default {
    components:{
        FormInvoice,
        FormReceipt
    },
    data () {
        return{
            info:{
                email: '',
                number: '', //注文番号
                name: '' //宛名
            },
            api_response:{
                order_id: Number, //注文番号
                display_product_name: String, //商品名
                display_plan_name: String, //プラン名
                period: String, //期間
                quantity: String, //個数
                price: Number, //単価
                total_price_without_tax: Number, //税抜き金額
                total_price_with_tax: Number, //税込金額
                confirm_order: false,
                confirm_payment: false, //入金済みか,
                order_timestamp: String,
                billing_confirmed_timestamp: String
            },
            api_response_failed: false,
            failed_message: '',

            selected: 'first',
            flags:{
                preview_invoice: false,
                preview_receipt: false
            }
        }
    },
    computed:{
        atena: function(){
            var prefix = ""
            if(this.selected == 'first'){
                prefix = "様"
            }
            else{
                prefix = "御中"
            }

            return this.info.name + " " + prefix //TODO:
        }
    },
    props: {
    },
    methods:{
    //ライセンス情報の取得
        onSubmit : function(evt){
            evt.preventDefault();
            var vm = this
            var order_id = vm.info.number
            var license_api_url = process.env.VUE_APP_PAYMENT_API_V1_BASE + "/order/" + order_id
            var api_key = "4QwASh52BpDrPcTdNT829DCXpqApqiYi"
            axios.get(`${license_api_url}?mail=${vm.info.email}&api_key=${api_key}`)
                    .then(function(res){
                        //APIのレスポンスを取得
                        var r = res.data.result
                        console.log(r)
                        if (res.data.status == 'OK'){
                            vm.api_response.order_id = vm.info.number
                            vm.api_response.display_product_name = r.display_product_name
                            vm.api_response.display_plan_name = r.display_plan_name

                            vm.api_response.period = r.license_period
                            vm.api_response.quantity = r.license_quantity
                            vm.api_response.price = r.license_unit_price
                            vm.api_response.total_price_without_tax = r.total_price
                            vm.api_response.total_price_with_tax = r.billing_amount

                            vm.api_response.confirm_order = true
                            vm.api_response.confirm_payment = r.billing_confirmed

                            vm.api_response.order_timestamp = r.order_timestamp
                            vm.api_response.billing_confirmed_timestamp = r.billing_confirmed_timestamp

                            vm.api_response_failed = false;
                        }
                        else
                        {
                            vm.api_response.confirm_order = false
                            vm.api_response.confirm_payment = false

                            vm.api_response_failed = true
                            vm.failed_message = res.data.message
                        }
                    })
                    //サーバエラーの場合
                    // eslint-disable-next-line
                    .catch(function(error){
                        vm.api_response.confirm_order = false
                        vm.api_response.confirm_payment = false

                        vm.api_response_failed = true
                        vm.failed_message = "問題が発生しました。しばらく時間をおいてご利用ください。"
                    })
        },
      //請求書の作成
      create_invoice : function(){
        this.flags.preview_invoice = true
        this.flags.preview_receipt = false
      },
      //領収書の作成
      create_receipt: function(){
        this.flags.preview_invoice = false
        this.flags.preview_receipt = true
      }
    }
  }
</script>

<style scoped>
.row {
    margin-top:10px;
}

@media print {
    .sheet {
    break-after: always;
    }

    .sheets > :not(.sheet) {
        display: none;
    }
}
</style>
